<template>
  <div class="debug-obs mb-3">
    <h4 class="text-white">OBS CONTROLLER</h4>
    <!-- <button class="btn btn-secondary mr-1" @click="handleTerryDance">Terry Talking Head</button> -->
    <button
      class="btn btn-secondary mr-1"
      :class="{ 'btn-danger': isTerryDanceEnabled }"
      @click="
        handleSourceToggle({
          sceneName: 'scn_nst: Character__Terry',
          sourceName: 'Terry01 - Dance',
          isInit: false,
        })
      "
    >
      Terry Dance
    </button>
    <!-- <button class="btn btn-secondary mr-1" @click="handleTerryDance">Terry Props</button> -->
    <!-- <button class="btn btn-secondary mr-1" @click="handleTerryDance">Terry Raid</button> -->
    <!-- <button class="btn btn-secondary mr-1" @click="handleTerryDance">Terry Convo</button> -->
  </div>
</template>

<script>
  import OBSWebSocket, { EventSubscription } from "obs-websocket-js";

  const obs = new OBSWebSocket();

  export default {
    name: "DebugOBS",
    data() {
      return {
        isTerryDanceEnabled: null,
      };
    },
    async created() {
      // 1. https://github.com/obsproject/obs-websocket/blob/master/docs/generated/protocol.md#getscenelist
      // 2. https://github.com/obs-websocket-community-projects/obs-websocket-js

      try {
        const { obsWebSocketVersion, negotiatedRpcVersion } = await obs.connect(
          "ws://192.168.1.11:4455",
          "2EFIfb2TDrqVK8oz",
          {
            rpcVersion: 1,
          }
        );

        console.log(
          `Success! Connected to OBS websocket server (obsWebSocketVersion:${obsWebSocketVersion}) (using RPC ${negotiatedRpcVersion})`
        );

        this.handleSourceToggle({
          sceneName: "scn_nst: Character__Terry",
          sourceName: "Terry01 - Dance",
          isInit: true,
        });
      } catch (error) {
        console.error("Failed to connect", error.code, error.message);
      }
    },
    methods: {
      async handleSourceToggle({ sceneName = "", sourceName = "", isInit = null }) {
        const { sceneItemEnabled, sceneItemId } = await this.getSceneSource(sceneName, sourceName);

        // UPDATE OBS SOURCE
        if (!isInit) {
          await obs.call("SetSceneItemEnabled", {
            sceneName,
            sceneItemId,
            sceneItemEnabled: !sceneItemEnabled,
          });
        }

        this.isTerryDanceEnabled = isInit ? sceneItemEnabled : !sceneItemEnabled;
        // console.log(`Visibility of src toggled to ${!sceneItemEnabled}!`);
      },

      /**
       * GET SCENE SOURCE
       */
      async getSceneSource(sceneName = "scn: MAIN_CONTROLLER", sourceName = "scn_grp: Terry") {
        const { sceneItems } = await obs.call("GetSceneItemList", {
          sceneName,
        });

        const sourceItem = sceneItems.find((item) => item.sourceName === sourceName);
        return sourceItem;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
